import { Gender } from "../interfaces/gender.enum";

export class Client {
	constructor(model: Partial<Client>) {
		Object.assign(this, model);
	}

	id: string;
	email: string;
	name: string;
	isActive: boolean;
	isComplete: boolean;
	lastName: string;
	birthDate: Date;
	solutionStartDate: Date;
	corporateClient: any;
	insurerPlan: any;
	insurerNumber: number;
	phoneNumber: string;
	gender: Gender;
	cpf: string;
	addressStreet: string;
	addressNumber: string | "N/A";
	addressComplement: string;
	addressNeighborhood: string;
	addressCity: string;
	addressState: string;
	addressPostCode: string;
	secondaryInfoGender: Gender;
	responsibleNavigationName: string;
	responsibleMedicalTeamName: string;
	treatmentForm: string;
	empathy: string;
	antecedentDiseases: any[];
	treatment: string;
	dependency: string;
	secondEmail: string;
	secondPhoneNumber: string;
	serviceDeliveryAddress: string;
	caregiver: string;
	contactPhoneNumber: string;
	caregiverBond: string;
	holder: string;
	dependents: string;
	enterprise: string;
	emergencyPhoneNumber: string;
	restriction: string;
	homeCare: boolean;
	proceduresCharge: string;
	planInfoTreatment: string;
	planInfoDependency: string;
	insurerPlanRelationKind: InsurerPlanRelationKind;
	loyalUser: string;
	"corporateClient.id": string;
}

export enum InsurerPlanRelationKind {
	DEPENDENT = "DEPENDENT",
	HOLDER = "HOLDER",
}

export enum FieldsClient {
	email = "email",
	name = "name",
	isActive = "isActive",
	lastName = "lastName",
	birthDate = "birthDate",
	corporateClient = "corporateClient",
	insurerPlan = "insurerPlan",
	insurerNumber = "insurerNumber",
	phoneNumber = "phoneNumber",
	gender = "gender",
	cpf = "cpf",
	address = "address",
	addressStreet = "addressStreet",
	addressNumber = "addressNumber",
	addressComplement = "addressComplement",
	addressCity = "addressCity",
	addressState = "addressState",
	addressPostCode = "addressPostCode",
	secondaryInfoGender = "secondaryInfoGender",
	responsibleNavigationName = "responsibleNavigationName",
	responsibleMedicalTeamName = "responsibleMedicalTeamName",
	treatmentForm = "treatmentForm",
	empathy = "empathy",
	secondEmail = "secondEmail",
	secondPhoneNumber = "secondPhoneNumber",
	serviceDeliveryAddress = "serviceDeliveryAddress",
	caregiver = "caregiver",
	contactPhoneNumber = "contactPhoneNumber",
	caregiverBond = "caregiverBond",
	enterprise = "enterprise",
	emergencyPhoneNumber = "emergencyPhoneNumber",
	restriction = "restriction",
	homeCare = "homeCare",
	proceduresCharge = "proceduresCharge",
	planInfoTreatment = "planInfoTreatment",
	planInfoDependency = "planInfoDependency",
}

export const fieldsClientTranslated: Record<FieldsClient, string> = {
	[FieldsClient.address]: "Endereço",
	[FieldsClient.addressCity]: "Cidade",
	[FieldsClient.addressComplement]: "Complemento do numero do imóvel",
	[FieldsClient.addressNumber]: "Numero do imóvel",
	[FieldsClient.addressPostCode]: "CEP",
	[FieldsClient.addressState]: "Estado",
	[FieldsClient.addressStreet]: "Rua",
	[FieldsClient.birthDate]: "Data de nascimento",
	[FieldsClient.corporateClient]: "Cliente corporativo",
	[FieldsClient.caregiver]: "Cuidador ou responsável legal",
	[FieldsClient.caregiverBond]: "Vínculo do cuidador ou responsável",
	[FieldsClient.contactPhoneNumber]: "Contato com DDD",
	[FieldsClient.cpf]: "CPF",
	[FieldsClient.email]: "E-mail",
	[FieldsClient.emergencyPhoneNumber]: "Número de emergência",
	[FieldsClient.empathy]: "Para gerar empatia",
	[FieldsClient.enterprise]: "Empresa",
	[FieldsClient.gender]: "Sexo",
	[FieldsClient.homeCare]: "Disponibilidade de atendimento domiciliar",
	[FieldsClient.insurerNumber]: "Nº da carteira",
	[FieldsClient.insurerPlan]: "Plano",
	[FieldsClient.isActive]: "Ativo",
	[FieldsClient.lastName]: "Sobrenome",
	[FieldsClient.name]: "Nome",
	[FieldsClient.phoneNumber]: "Nº de telefone",
	[FieldsClient.planInfoDependency]: "Dependência",
	[FieldsClient.planInfoTreatment]: "Tratamento",
	[FieldsClient.proceduresCharge]: "Pagamento para consulta e procedimentos",
	[FieldsClient.responsibleMedicalTeamName]: "Vínculo com equipe médica",
	[FieldsClient.responsibleNavigationName]: "Vínculo com Navegação",
	[FieldsClient.restriction]: "Carência ou restrição",
	[FieldsClient.secondEmail]: "E-mail Alternativo",
	[FieldsClient.secondPhoneNumber]: "Telefone Alternativo",
	[FieldsClient.secondaryInfoGender]: "Gênero Secundario",
	[FieldsClient.serviceDeliveryAddress]: "Endereço eventual de prestação de serviço",
	[FieldsClient.treatmentForm]: "Forma de tratamento",
};

export function translateFieldsClient(field?: FieldsClient): string {
	if (!field) return "";

	if (field?.includes(".")) {
		const fieldSplitted = field.split("");
		const foundDotIndex = fieldSplitted.findIndex(letter => letter === ".");
		const mappingfieldSplitted = fieldSplitted.map((letter, index) => {
			return index === foundDotIndex + 1 ? letter.toLocaleUpperCase() : letter;
		});

		mappingfieldSplitted.splice(foundDotIndex, 1);
		const fieldFormatted = mappingfieldSplitted.join("") as unknown as FieldsClient;

		return fieldsClientTranslated[fieldFormatted];
	}

	return fieldsClientTranslated[field];
}
