import { IUser } from "@/interfaces/user";
import { CrudModel } from "./_crud";

export class UserModel extends CrudModel {
	constructor() {
		super("admin/user");
	}

	async getProfile() {
		return this.get("profile");
	}

	async updateProfile(id: string, userInfo: Pick<IUser, "name" | "lastName" | "email">) {
		return this.update(id, userInfo);
	}

	async updatePassword(currentPassword: string, password: string) {
		return this.patch(null, "password", { currentPassword, password });
	}

	async report(corporateClientId?: string) {
		return this.get("report", { corporateClientId });
	}
}

export default new UserModel();
