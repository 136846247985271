import { ListOptionsDto } from "@/interfaces/list_options.dto";
import { SolutionAppointmentModel } from "./solution_appointment.model";
import { CrudModel } from "./_crud";
import { crudGet } from "./_request";

export class clientModel extends CrudModel {
	constructor() {
		super("admin/client", [], [{ fieldKey: "appointments", model: SolutionAppointmentModel }]);
	}
	async getClientAdminList(listDto: Pick<ListOptionsDto, "searchParam" | "limit" | "page">) {
		return this.get("", listDto);
	}

	async search({
		page,
		limit,
		searchParam,
		enterpriseId,
		insurerPlanId,
		corporateClientId,
		tagId,
		aboveAge,
		belowAge,
		shouldReturnOnlyTotalCount,
		weeksSinceLastMessageSent,
		clientIds,
		shouldGetOnlyActiveClient = false,
	}: any) {
		return crudGet(this.endpoint, "", {
			page,
			limit,
			searchParam,
			enterpriseId,
			insurerPlanId,
			corporateClientId,
			tagId,
			aboveAge,
			belowAge,
			shouldReturnOnlyTotalCount,
			weeksSinceLastMessageSent,
			clientIds,
			shouldGetOnlyActiveClient,
		});
	}
}

export default new clientModel();
