





























































































import { Component, Vue, Prop } from "vue-property-decorator";
import CustomButton from "@/layouts/components/Button.vue";
import { tertiaryBody, tertiaryBoldBody } from "@/helpers/styleClassHelpers";
import CustomTabs from "@/layouts/components/CustomTabs.vue";
import { hasMissingRequiredKeys, showErrorAlert, showSuccessAlert } from "@/helpers";
import { BOverlay } from "bootstrap-vue";
import EntityForm from "./entity/EntityForm.vue";
import { translateFieldsClient } from "@/entities/client";

@Component({
	components: {
		CustomButton,
		"custom-tabs": CustomTabs,
		BOverlay,
	},
})
export default class InclusionType extends Vue {
	@Prop({ default: () => ({}) }) creationComponent!: any;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) labelCreate!: string;
	@Prop({ default: "" }) linkDownload!: string;
	@Prop({ default: () => null }) onCreate!: Function;
	@Prop({ default: () => ({}) }) model!: any;

	tertiaryBody = tertiaryBody;
	tertiaryBoldBody = tertiaryBoldBody;

	translateFieldsClient = translateFieldsClient;

	inclusionOptionSelected = "importation";
	isLoading = false;
	fileUploadName: string = "";
	currentFileUpload = {} as File;
	formData = new FormData();
	currentError: any = "";

	hasFile = false;

	inclusionOptions = [
		{ label: "Manual", value: "manual" },
		{ label: "Arquivo.xlsx", value: "importation" },
	];

	downloadModel() {
		if (!this.linkDownload) {
			return;
		}
		window.open(this.linkDownload, "_blank");
	}

	async sendImport() {
		this.isLoading = true;
		try {
			if (this.formData.has("file")) {
				this.formData.delete("file");
			}

			if (!this.hasFile) {
				showErrorAlert("É necessário fazer o upload do arquivo.");
				return;
			}

			this.formData.append("file", this.currentFileUpload);
			await this.model.import(this.formData);
			showSuccessAlert("O arquivo foi importado com sucesso");
			this.$router.back();
		} catch (error: any) {
			console.error(error);
			this.currentError = error;
			showErrorAlert(error?.friendlyMessage || error?.message || "Ocorreu um erro na importação. Tente novamente.");
		} finally {
			this.currentFileUpload = {} as File;
			this.fileUploadName = "";
			this.isLoading = false;
		}
	}

	preventDefault(event: Event) {
		event.preventDefault();
	}

	uploadFile(event: any) {
		event.preventDefault();
		const fileInputDataTransfer: any = <any>this.$refs.fileInput;
		const dataTransfer = event.dataTransfer;
		this.hasFile = true;

		this.currentFileUpload = dataTransfer ? dataTransfer.files[0] : fileInputDataTransfer.files[0];
		this.fileUploadName = dataTransfer ? dataTransfer.files[0].name : fileInputDataTransfer.files[0].name;
	}

	async create() {
		this.isLoading = true;
		try {
			await this.cleanForm();
			const currentEntity = await this.creationComponent.model.create(this.creationComponent.customEntity);
			await this.$router.replace(this.$router.currentRoute.path.replace("novo", currentEntity.id));
			location.reload();
		} catch (error: any) {
			this.setErrors(error, this.creationComponent);
			showErrorAlert(error?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
			console.error(error);
		} finally {
			this.isLoading = false;
		}
	}

	setErrors(errors: any, forms: any) {
		if (forms.component === EntityForm) {
			forms.props.newErrors = errors;
		} else if (forms.component === CustomTabs) {
			forms.props.tabs.forEach((tab: any) => {
				this.setErrors(errors, tab);
			});
		}
	}

	clearErrors() {
		this.currentError = "";
	}

	get buttonDisabled() {
		if (!hasMissingRequiredKeys(this.creationComponent.fieldsRequired, this.creationComponent.customEntity)) {
			return false;
		}
		return true;
	}

	async cleanForm() {
		Object.keys(this.creationComponent.customEntity).forEach(key => {
			if (
				[undefined, null, "", 0].includes(this.creationComponent.customEntity[key]) ||
				!this.creationComponent.customEntity[key]
			) {
				delete this.creationComponent.customEntity[key];
				return;
			}
		});
	}
}
