import { CrudModel } from "./_crud";
import { crudGet, crudRead } from "./_request";

export class tagModel extends CrudModel {
	constructor() {
		super("admin/tag", [], []);
	}

	override async read(id: string) {
		const queryParameters = {
			join: await this.getJoinableFields(),
		};

		return crudRead(this.endpoint, id, queryParameters);
	}

	async search({ page, limit, searchParam }: any) {
		return crudGet(this.endpoint, "", { page, limit, searchParam });
	}
}

export default new tagModel();
