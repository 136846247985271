import { CrudModel } from "./_crud";

export class SolutionAppointmentModel extends CrudModel {
	constructor() {
		super("admin/solutionAppointment");
	}
	async getAppointments(params: any) {
		return this.getParam(params);
	}
}

export default new SolutionAppointmentModel();
