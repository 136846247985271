import { render, staticRenderFns } from "./InclusionType.vue?vue&type=template&id=7878b101&scoped=true&"
import script from "./InclusionType.vue?vue&type=script&lang=ts&"
export * from "./InclusionType.vue?vue&type=script&lang=ts&"
import style0 from "./InclusionType.vue?vue&type=style&index=0&id=7878b101&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7878b101",
  null
  
)

export default component.exports