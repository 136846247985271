































































import { spreadsheetTemplates } from "@/helpers/templateImport";
import { formatDayMonthYear, isValidCPF } from "@/helpers/utils";
import { translateInsurerPlanRelationKind } from "@/interfaces/corporate_client";
import { IEnterprise } from "@/interfaces/enterprise";
import { translateServicePlanKind } from "@/interfaces/service_plan";
import { UserRole, translateGender } from "@/interfaces/user";
import ActionPlan from "@/layouts/components/ActionPlan.vue";
import AppointmentsHistory from "@/layouts/components/AppointmentsHistory.vue";
import CustomTabs from "@/layouts/components/CustomTabs.vue";
import Guides from "@/layouts/components/Guides.vue";
import CustomUserCard from "@/layouts/components/UserCard.vue";
import { BOverlay, BSpinner } from "bootstrap-vue";
import debounce from "debounce";
import { Component, Vue, Watch } from "vue-property-decorator";
import clientModel from "../../api/client.model";
import corporateClient from "../../api/corporate_client.model";
import disease from "../../api/disease.model";
import enterprise from "../../api/enterprise.model";
import insurerPlan from "../../api/insurer_plan.model";
import solutionAppointment from "../../api/solution_appointment.model";
import tag from "../../api/tag.model";
import treatment from "../../api/treatment.model";
import user from "../../api/user.model";
import { Client } from "../../entities/client";
import { requireConfirmation, showErrorAlert, showSuccessAlert } from "../../helpers";
import CustomButton from "../../layouts/components/Button.vue";
import InclusionType from "../InclusionType.vue";
import EntityForm from "../entity/EntityForm.vue";

@Component({
	components: {
		"custom-button": CustomButton,
		"user-card": CustomUserCard,
		"custom-tabs": CustomTabs,
		BOverlay,
		BSpinner,
		InclusionType,
	},
})
export default class ClientsForm extends Vue {
	isEditing = true;
	currentTabIndex = 0;
	modelForm = new Client({}) as any;
	currentSecondaryTabIndex = 0;
	loading = false;
	loadingErrorButton = false;
	loadingButton = false;
	corporateClientsField: any;
	corporateClientId = "";
	enterpriseId = "";
	currentTreatment = "";
	currentTag = "";
	isEntityLoading = false;
	mainEntity = {};
	buttonDisabled = false;
	localIsReadOnly = true;
	clientModel = clientModel;
	templateDownload = spreadsheetTemplates.clients;
	enterpriseSearchParam: string | undefined;

	debouncedSearch = debounce(this.changeTreatment, 300);
	debouncedEnterpriseSearch = debounce(this.searchEnterprise, 300);
	debouncedTagSearch = debounce(this.changeTag, 300);

	constructor() {
		super();
		this.debouncedSearch = debounce(this.changeTreatment, 300);
	}

	formColumnsMainData: any = [
		{ key: "name", name: "Nome", required: true },
		{ key: "lastName", name: "Sobrenome", required: true },
		{ key: "birthDate", readOnly: false, required: true, onTransform: formatDayMonthYear },
		{ key: "gender", onTransform: translateGender },
		{ key: "insurerNumber", kind: "text" },
		{ key: "phoneNumber" },
		{ key: "cpf", required: true, validationField: isValidCPF, messageError: "CPF inválido" },
		{ key: "email" },
		{
			key: "address.postCode",
			autoComplete: true,
			autoCompleteKeys: ["address.street", "address.neighborhood", "address.city", "address.state"],
		},
		{ key: "address.street" },
		{ key: "address.number" },
		{ key: "address.complement" },
		{ key: "address.neighborhood" },
		{ key: "address.city" },
		{
			key: "address.state",
		},
		{
			key: "loyalUser.id",
			name: "Fidelizador",
			kind: "select",
			options: [{}],
			showFullName: true,
		},
		{
			key: "corporateClient.id",
			name: "Cliente corporativo",
			required: true,
			kind: "select",
			options: [{}],
		},
		{
			key: "insurerPlan.id",
			name: "Plano",
			required: true,
			kind: "select",
			options: [{}],
			validationToShow: (entity: any) => {
				return entity["corporateClient.id"] !== "";
			},
		},
	];

	formColumnsDataNavigation: any = [
		"responsibleNavigationName",
		"responsibleMedicalTeamName",
		"treatmentForm",
		"empathy",
		{
			key: "firstSolutionAppointmentDate",
			name: "Data de início",
			onTransform: (firstSolutionAppointmentDate: Date) => formatDayMonthYear(firstSolutionAppointmentDate),
		},
		{
			key: "isActive",
			inputKind: "selectButtons",
			options: [
				{ name: "Sim", value: true },
				{ name: "Não", value: false },
			],
		},
		{
			key: "antecedentDiseases",
			kind: "multiselect",
			emptyValue: [],
			relationKey: "disease",
			formatEntity: true,
			onTransform: this.formatReadOnlyDisease,
			options: [{}],
		},
		{
			key: "clientTreatments",
			kind: "multiselect",
			emptyValue: [],
			relationKey: "treatment",
			formatEntity: true,
			shouldDisplayAnyway: true,
			onTransform: this.formatReadOnlyTreatment,
			onSearch: this.debouncedSearch,
			options: [{}],
		},
		{
			key: "clientTags",
			kind: "multiselect",
			emptyValue: [],
			relationKey: "tag",
			formatEntity: true,
			shouldDisplayAnyway: true,
			onTransform: this.formatReadOnlyTag,
			onSearch: this.debouncedTagSearch,
			options: [{}],
		},
		{
			key: "planInfoDependency",
			inputKind: "selectButtons",
			options: [
				{ name: "Sim", value: 1 },
				{ name: "Não", value: 0 },
			],
		},
	];

	formColumnsSecondaryData: any = [
		{ key: "secondEmail" },
		"secondPhoneNumber",
		"serviceDeliveryAddress",
		"isSecondaryAddressMain",
		{
			key: "secondaryAddress.postCode",
			autoComplete: true,
			autoCompleteKeys: [
				"secondaryAddress.street",
				"secondaryAddress.neighborhood",
				"secondaryAddress.city",
				"secondaryAddress.state",
			],
		},
		"secondaryAddress.street",
		"secondaryAddress.number",
		"secondaryAddress.complement",
		"secondaryAddress.neighborhood",
		"secondaryAddress.city",
		"secondaryAddress.state",
	];

	formColumnsCaregiver = ["caregiver", "contactPhoneNumber", "caregiverBond"];

	formColumnsInsurerPlanInformation = [
		{ key: "insurerPlan.name", name: "Nome do plano", isReadOnly: true },
		{
			key: "insurerPlan.emergencyService",
			inputKind: "selectButtons",
			isReadOnly: true,
			options: [
				{ name: "Sim", value: true },
				{ name: "Não", value: false },
			],
		},
		{
			key: "insurerPlan.emergencyServiceNumber",
			isReadOnly: true,
			validationToShow: (entity: any) => {
				return entity["insurerPlan.emergencyService"];
			},
		},
		{
			key: "insurerPlan.servicePlans",
			name: "Serviços",
			kind: "customForm",
			onTransform: this.formatReadOnlyServices,
			isReadOnly: true,
		},
		{
			key: "insurerPlanRelationKind",
			inputKind: "selectButtons",
			onTransform: translateInsurerPlanRelationKind,
			options: [
				{ name: "Dependente", value: "DEPENDENT" },
				{ name: "Titular", value: "HOLDER" },
			],
		},
		"restriction",
		"homeCare",
		"proceduresCharge",
		{
			key: "enterprise.id",
			name: "Empresa",
			required: false,
			kind: "select",
			options: [{}],
			onSearch: this.debouncedEnterpriseSearch,
		},
	];

	guides = [
		{
			name: "Contexto Social e Clínico",
			helpText: "Resumo do ambiente familiar e das situações de saúde vivenciadas pelo cliente",
			displayingHelp: false,
			isOpen: false,
			editIcon: true,
			component: EntityForm,
			componentName: "EntityForm",
			onClick: this.unlockEditionGuide,
			props: {
				isLoadEntity: false,
				onCreateOrUpdateEntity: this.lockEdition,
				model: clientModel,
				formColumns: [{ key: "socialContext", name: "", inputKind: "textarea" }],
				withBackButton: false,
				isCustomEntity: true,
				customEntity: this.modelForm,
			},
		},
		{
			name: "Plano de ação",
			totalData: 0,
			helpText:
				"Lista de soluções ofertadas para atender a necessidade do cliente e que estão pendentes de realização e de avaliação",
			displayingHelp: false,
			isOpen: false,
			showTotal: true,
			component: ActionPlan,
			componentName: "ActionPlan",
			props: {
				appointments: [] as any,
				onAction: this.appointmentActions,
			},
		},
		{
			name: "Avaliação do paciente pós orientação",
			totalData: 0,
			helpText: "Lista de soluções orientadas a necessidade do cliente e que estão pendentes de avaliação",
			displayingHelp: false,
			isOpen: false,
			showTotal: true,
			component: ActionPlan,
			props: {
				appointments: [] as any,
				onAction: this.appointmentActions,
			},
		},
		{
			name: "Jornada de cuidado",
			totalData: 0,
			helpText:
				"Descritivos das soluções anteriores já ofertadas para o cliente e avaliação da satisfação do cliente em relação a solução proposta",
			displayingHelp: false,
			isOpen: false,
			showTotal: true,
			component: AppointmentsHistory,
			componentName: "AppointmentsHistory",
			props: {
				appointments: [] as any,
			},
		},
	];

	get tabsSecondaryData() {
		return [
			{
				name: "Dados de Navegação",
				component: EntityForm,
				componentName: "EntityForm",
				props: {
					isLoadEntity: false,
					onCreateOrUpdateEntity: this.lockEdition,
					model: clientModel,
					formColumns: this.formColumnsDataNavigation,
					withBackButton: false,
					isCustomEntity: true,
					customEntity: this.modelForm,
				},
			},
			{
				name: "Outros Contatos",
				component: EntityForm,
				componentName: "EntityForm",
				props: {
					isLoadEntity: false,
					onCreateOrUpdateEntity: this.lockEdition,
					model: clientModel,
					formColumns: this.formColumnsSecondaryData,
					withBackButton: false,
					isCustomEntity: true,
					customEntity: this.modelForm,
					withPostalCode: true,
				},
			},
			{
				name: "Suporte social",
				component: EntityForm,
				componentName: "EntityForm",
				props: {
					isLoadEntity: false,
					onCreateOrUpdateEntity: this.lockEdition,
					model: clientModel,
					formColumns: this.formColumnsCaregiver,
					withBackButton: false,
					isCustomEntity: true,
					customEntity: this.modelForm,
				},
			},
			{
				name: "Informações do Plano",
				component: EntityForm,
				componentName: "EntityForm",
				props: {
					isLoadEntity: false,
					onCreateOrUpdateEntity: this.lockEdition,
					model: clientModel,
					formColumns: this.formColumnsInsurerPlanInformation,
					withBackButton: false,
					isCustomEntity: true,
					customEntity: this.modelForm,
					cleanFormKeys: {
						deleteKeys: [
							"insurerPlan.name",
							"insurerPlan.emergencyServiceNumber",
							"insurerPlan",
							"insurerPlan.servicePlans",
							"enterpriseId",
						],
					},
				},
			},
		];
	}

	tabsSecondaryCreationData = [
		{
			name: "Dados de Navegação",
			component: EntityForm,
			componentName: "EntityForm",
			props: {
				isLoadEntity: false,
				isCustomEntity: true,
				customEntity: this.mainEntity,
				model: clientModel,
				formColumns: this.formColumnsDataNavigation,
				withBackButton: false,
				newErrors: {},
			},
		},
		{
			name: "Outros Contatos",
			component: EntityForm,
			componentName: "EntityForm",
			props: {
				isLoadEntity: false,
				isCustomEntity: true,
				customEntity: this.mainEntity,
				model: clientModel,
				formColumns: this.formColumnsSecondaryData,
				withBackButton: false,
				newErrors: {},
				cleanFormKeys: {
					deleteKeys: ["secondaryAddress.googleMapsPlaceId"],
				},
			},
		},
		{
			name: "Suporte social",
			component: EntityForm,
			componentName: "EntityForm",
			props: {
				isLoadEntity: false,
				isCustomEntity: true,
				customEntity: this.mainEntity,
				model: clientModel,
				formColumns: this.formColumnsCaregiver,
				withBackButton: false,
				newErrors: {},
			},
		},
		{
			name: "Informações do Plano",
			component: EntityForm,
			componentName: "EntityForm",
			props: {
				isLoadEntity: false,
				isCustomEntity: true,
				customEntity: this.mainEntity,
				model: clientModel,
				formColumns: this.formColumnsInsurerPlanInformation,
				withBackButton: false,
				newErrors: {},
				cleanFormKeys: {
					deleteKeys: ["insurerPlan.name", "insurerPlan.emergencyServiceNumber", "insurerPlan", "enterpriseId"],
				},
			},
		},
	];

	get mainTabs() {
		return [
			{
				name: "Dados Principais",
				component: EntityForm,
				componentName: "EntityForm",
				props: {
					isLoadEntity: false,
					isCustomEntity: true,
					customEntity: this.modelForm,
					onCreateOrUpdateEntity: this.lockEdition,
					model: clientModel,
					formColumns: this.formColumnsMainData,
					withBackButton: false,
					withPostalCode: true,
					cleanFormKeys: {
						deleteKeys: [
							"id",
							"createdDate",
							"updatedDate",
							"latitude",
							"longitude",
							"googlePlacesId",
							"isComplete",
							"address.googleMapsPlaceId",
							"googleMapsPlaceId",
							"loyalUser.name",
							"loyalUser.lastName",
						],
						holdKeys: ["corporateClient.id"],
					},
					onInputChange: this.changeSelect,
				},
			},
			{
				name: "Dados Secundários",
				component: CustomTabs,
				componentName: "CustomTabs",
				props: {
					tabs: this.tabsSecondaryData,
					selectedHeaderClass: "text-journey-green",
				},
			},
			{
				name: "Perfil do cliente",
				component: Guides,
				componentName: "Guides",
				props: {
					guides: this.guides,
				},
			},
		];
	}

	creationTabs = [
		{
			name: "Dados Principais",
			component: EntityForm,
			componentName: "EntityForm",
			props: {
				isLoadEntity: false,
				isCustomEntity: true,
				customEntity: this.mainEntity,
				model: clientModel,
				formColumns: this.formColumnsMainData,
				withBackButton: false,
				withPostalCode: true,
				newErrors: {},
				cleanFormKeys: {
					deleteKeys: [
						"id",
						"createdDate",
						"updatedDate",
						"latitude",
						"longitude",
						"googlePlacesId",
						"isComplete",
						"googleMapsPlaceId",
						"corporateClient",
						"corporateClient.id",
						"loyalUser.name",
						"loyalUser.lastName",
					],
				},
				onInputChange: this.changeSelect,
			},
		},
		{
			name: "Dados Secundários",
			component: CustomTabs,
			componentName: "CustomTabs",
			props: {
				tabs: this.tabsSecondaryCreationData,
				selectedHeaderClass: "text-journey-green",
			},
		},
	];

	creationComponent = {
		component: CustomTabs,
		componentName: "CustomTabs",
		props: {
			tabs: this.creationTabs,
		},
		isCustomEntity: true,
		customEntity: this.mainEntity,
		model: clientModel,
		fieldsRequired: [
			{ key: "name", required: true },
			{ key: "lastName", required: true },
			{ key: "birthDate", required: true },
			{ key: "cpf", required: true },
			{ key: "corporateClient.id", required: true },
			{ key: "insurerPlan.id", required: true },
		],
	};

	headerButtons = [
		{ icon: "edit-icon.svg", variant: "tertiary", buttonAction: this.unlockEdition },
		{ icon: "trash-icon.svg", variant: "tertiary", buttonAction: this.deleteClient },
	];

	cardHeader = [
		{ key: "name", kind: "userName" },
		{ key: "lastName", kind: "userName" },
		{
			content: [
				{ key: "birthDate", kind: "age" },
				{
					key: "gender",
					kind: "status",
				},
				{
					key: "corporateClient.name",
				},
				{ key: "isActive", kind: "isActive" },
			],
		},
	];

	get currentId() {
		return this.$route.params.id;
	}

	formatReadOnlyServices(services: any[]) {
		if (!services || !services.length) {
			return;
		}
		return services?.reduce((stringServices, currentService, indexService) => {
			return `${stringServices} ${translateServicePlanKind(currentService.serviceKind)}${
				currentService.otherServiceKind ? " - " + currentService.otherServiceKind : ""
			} - ${currentService.description}${indexService !== services.length - 1 && services.length > 1 ? "<br>" : ""}`;
		}, "");
	}

	async getAppointments() {
		const appointments = await solutionAppointment.getAppointments({
			limit: 0,
			page: 1,
			clientId: this.currentId,
			shouldOrderByAppointmentCreatedDate: true,
			shouldGetWithoutDateFilter: true,
		});

		const guidePlanAction = this.guides.find(guide => guide.name === "Plano de ação");
		const guideClientReview = this.guides.find(guide => guide.name === "Avaliação do paciente pós orientação");
		const guideAppointmentsHistory = this.guides.find(guide => guide.name === "Jornada de cuidado");

		guidePlanAction!.props.appointments = [];
		guideClientReview!.props.appointments = [];
		guideAppointmentsHistory!.props.appointments = [];

		appointments.data.forEach((appointment: any) => {
			if (
				["Orientações", "Sem necessidade"].includes(appointment.solution.category.name) &&
				appointment.status === "CONFIRMED"
			) {
				guideClientReview?.props.appointments.push(appointment);
			} else if (appointment.status === "CONFIRMED") {
				guidePlanAction?.props.appointments.push(appointment);
			} else if (appointment.status !== "PRE_SELECTED") {
				guideAppointmentsHistory?.props.appointments.push(appointment);
			}
		});

		guidePlanAction!.totalData = guidePlanAction?.props.appointments.length;
		guideClientReview!.totalData = guideClientReview?.props.appointments.length;
		guideAppointmentsHistory!.totalData = guideAppointmentsHistory?.props.appointments.length;
	}

	async approvedAppointment(appointment: any) {
		this.isEntityLoading = true;
		try {
			await solutionAppointment.patch(appointment.id, "complete", {
				ratingValue: appointment.ratingValue,
			});
			await this.getAppointments();
			showSuccessAlert("Os dados foram registrados.");
		} catch (error: any) {
			const errorMessage: string | undefined = error?.friendlyMessage ?? error?.message;
			showErrorAlert(errorMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isEntityLoading = false;
		}
	}

	async refusedAppointment(appointment: any) {
		this.isEntityLoading = true;
		try {
			await solutionAppointment.patch(appointment.id, "fail", {
				reason: appointment.reason,
				description: appointment.description,
			});
			await this.getAppointments();
			showSuccessAlert("Os dados foram registrados.");
		} catch (error: any) {
			const errorMessage: string | undefined = error?.friendlyMessage ?? error?.message;
			showErrorAlert(errorMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isEntityLoading = false;
		}
	}

	async updateAppointmentReturnDate(appointment: any) {
		this.isEntityLoading = true;
		try {
			await solutionAppointment.patch(appointment.id, "updateReturnDate", {
				returnDate: appointment.returnDate,
			});
			await this.getAppointments();
			showSuccessAlert("Os dados foram registrados.");
		} catch (error: any) {
			const errorMessage: string | undefined = error?.friendlyMessage ?? error?.message;
			showErrorAlert(errorMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isEntityLoading = false;
		}
	}

	appointmentActions(action: string, value: any, appointment: any) {
		switch (action) {
			case "approved":
				appointment.ratingValue = value;
				this.approvedAppointment(appointment);
				break;
			case "refused":
				appointment.reason = value.reason;
				appointment.description = value.description;
				this.refusedAppointment(appointment);
				break;
			case "updateReturnDate":
				appointment.returnDate = value.ReturnDateUpdate;
				this.updateAppointmentReturnDate(appointment);
				break;
			default:
				break;
		}
	}

	async searchEnterprise(value: string) {
		this.enterpriseSearchParam = value;
		const enterprises = await enterprise.search({ page: 1, limit: 50, searchParam: value });

		let planInformationData = {} as any;

		if (this.currentId === "novo") {
			planInformationData = this.creationComponent.props.tabs
				.find(tab => tab.name === "Dados Secundários")
				?.props.tabs?.find(tab => tab.name === "Informações do Plano");
		} else {
			planInformationData = this.mainTabs
				.find(tab => tab.name === "Dados Secundários")
				?.props.tabs?.find(tab => tab.name === "Informações do Plano");
		}

		const enterpriseField = planInformationData!.props.formColumns.find(
			(prop: { key: string }) => prop.key === "enterprise.id",
		);

		enterprises.data.forEach((enterprise: IEnterprise) => {
			if (!enterpriseField.options.find((enterpriseOption: any) => enterpriseOption.name === enterprise.name)) {
				enterpriseField.options.push({ name: enterprise.name, value: enterprise.id });
			}
		});
	}

	async getRelationFields(isLoading = true) {
		this.isEntityLoading = true;
		try {
			const treatments = await treatment.search({
				search: { $or: [{ productName: { $cont: this.currentTreatment || "" } }] },
				page: 1,
				limit: 10,
			});
			const tags = await tag.search({
				searchParam: this.currentTag || "",
				page: 1,
				limit: 50,
			});
			const diseases = await disease.search({});
			const corporateClients = await corporateClient.search({});
			const insurerPlans = await insurerPlan.search(
				{
					search: { $or: [{ "corporateClient.id": { $cont: this.corporateClientId || "" } }] },
				},
				true,
			);
			const enterprises = await enterprise.search({ page: 1, limit: 50 });
			const users = await user.search({
				search: { $and: [{ role: UserRole.HEALTH_PROMOTER }] },
				page: 1,
				limit: 50,
			});

			if (!this.formColumnsDataNavigation.filter((field: any) => field.key === "antecedentDiseases").length) {
				this.formColumnsDataNavigation.unshift({
					key: "antecedentDiseases",
					kind: "multiselect",
					relationKey: "disease",
					formatEntity: true,
					onTransform: this.formatReadOnlyDisease,
					options: diseases.map((disease: any) => {
						return {
							name: disease.description,
							value: { disease: { id: disease.id, description: disease.description } },
						};
					}),
				});
			} else {
				this.formColumnsDataNavigation.find((field: any) => field.key === "antecedentDiseases").options =
					diseases.map((disease: any) => {
						return {
							name: disease.description,
							value: { disease: { id: disease.id, description: disease.description } },
						};
					});
			}

			if (!this.formColumnsDataNavigation.filter((field: any) => field.key === "clientTreatments").length) {
				this.formColumnsDataNavigation.unshift({
					key: "clientTreatments",
					kind: "multiselect",
					relationKey: "treatment",
					formatEntity: true,
					shouldDisplayAnyway: true,
					onTransform: this.formatReadOnlyTreatment,
					options: treatments.data.map((treatment: any) => {
						return {
							name: treatment.productName,
							value: { treatment: { id: treatment.id, productName: treatment.productName } },
						};
					}),
					onSearch: this.debouncedSearch,
				});
			} else {
				this.formColumnsDataNavigation.find((field: any) => field.key === "clientTreatments").options =
					treatments.data.map((treatment: any) => {
						return {
							name: treatment.productName,
							value: { treatment: { id: treatment.id, productName: treatment.productName } },
						};
					});
			}

			if (!this.formColumnsDataNavigation.filter((field: any) => field.key === "clientTags").length) {
				this.formColumnsDataNavigation.unshift({
					emptyValue: [],
					key: "clientTags",
					kind: "multiselect",
					relationKey: "tag",
					formatEntity: true,
					shouldDisplayAnyway: true,
					onTransform: this.formatReadOnlyTag,
					options: tags.data.map((tag: any) => {
						return {
							name: tag.name,
							value: { tag: { id: tag.id, name: tag.name } },
						};
					}),
					onSearch: this.debouncedTagSearch,
				});
			} else {
				this.formColumnsDataNavigation.find((field: any) => field.key === "clientTags").options = tags.data.map(
					(tag: any) => {
						return {
							name: tag.name,
							value: { tag: { id: tag.id, name: tag.name } },
						};
					},
				);
			}

			let mainData = {} as any;
			let planInformationData = {} as any;

			if (this.currentId === "novo") {
				mainData = this.creationComponent.props.tabs.find(tab => tab.name === "Dados Principais");
				planInformationData = this.creationComponent.props.tabs
					.find(tab => tab.name === "Dados Secundários")
					?.props.tabs?.find(tab => tab.name === "Informações do Plano");
			} else {
				mainData = this.mainTabs.find(tab => tab.name === "Dados Principais");
				planInformationData = this.mainTabs
					.find(tab => tab.name === "Dados Secundários")
					?.props.tabs?.find(tab => tab.name === "Informações do Plano");
			}

			mainData!.props.formColumns.find((prop: { key: string }) => prop.key === "loyalUser.id").options =
				users.data.map((user: any) => {
					return { name: `${user.name}${user.lastName ? " " + user.lastName : ""}`, value: user.id };
				});

			mainData!.props.formColumns.find((prop: { key: string }) => prop.key === "corporateClient.id").options =
				corporateClients.map((corporateClient: any) => {
					return { name: corporateClient.name, value: corporateClient.id };
				});

			mainData!.props.formColumns.find((prop: { key: string }) => prop.key === "insurerPlan.id").options =
				insurerPlans.map((insurerPlan: any) => {
					return { name: insurerPlan.name, value: insurerPlan.id };
				});

			planInformationData!.props.formColumns.find((prop: { key: string }) => prop.key === "enterprise.id").options =
				enterprises.data.map((enterprise: any) => {
					return { name: enterprise.name, value: enterprise.id };
				});
		} catch (error) {
			console.error(error);
		} finally {
			this.isEntityLoading = false;
		}
	}

	changeTreatment(value: string) {
		this.currentTreatment = value;
	}

	changeTag(value: string) {
		this.currentTag = value;
	}

	@Watch("currentTreatment")
	@Watch("currentTag")
	handleSearch() {
		this.getRelationFields();
	}

	formatReadOnlyDisease(diseases: any[]) {
		if (!diseases || !diseases.length) {
			return;
		}
		const formatDiseases = diseases?.reduce((stringDiseases, currentDisease, indexDisease) => {
			return `${stringDiseases} ${currentDisease.disease?.description}${
				indexDisease !== diseases.length - 1 && diseases.length > 1 ? "," : ""
			}`;
		}, "");

		return formatDiseases;
	}

	formatReadOnlyTreatment(treatments: any[]) {
		if (!treatments || !treatments.length) {
			return;
		}
		const formatTreatments = treatments?.reduce((stringTreatments, currentTreatment, indexTreatment) => {
			return `${stringTreatments} ${currentTreatment.treatment?.productName}${
				indexTreatment !== treatments.length - 1 && treatments.length > 1 ? "," : ""
			}`;
		}, "");

		return formatTreatments;
	}

	formatReadOnlyTag(tags: any[]) {
		if (!tags || !tags.length) {
			return;
		}

		const formatTags = tags?.reduce((stringTag, currentTag, indexTag) => {
			return `${stringTag} ${currentTag.tag?.name}${indexTag !== tags.length - 1 && tags.length > 1 ? "," : ""}`;
		}, "");

		return formatTags;
	}

	toggleLockEdition(
		shouldBeLocked: boolean,
		startAffectOn?: string,
		ignoreOn?: string,
		componentRef: any = this.$refs.customTabsRef,
	) {
		startAffectOn = this.toKebabCase(startAffectOn);
		ignoreOn = this.toKebabCase(ignoreOn);
		const target = "entity-form";
		const tag = componentRef.$options._componentTag || this.toKebabCase(componentRef.$attrs.component);
		if (startAffectOn && (tag === startAffectOn || startAffectOn === "affect-all")) {
			startAffectOn = "affect-all";
			if (tag === target) {
				componentRef.isReadOnly = shouldBeLocked;
			}
		}
		for (const ref of componentRef.$children) {
			if (ignoreOn && tag === ignoreOn) {
				continue;
			}
			this.toggleLockEdition(shouldBeLocked, startAffectOn, ignoreOn, ref);
		}
	}

	toKebabCase(text?: string) {
		return text
			? text
					.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/gu)!
					.map((str: string) => str.toLowerCase())
					.join("-")
			: "";
	}

	unlockEditionGuide() {
		this.toggleLockEdition(false, "Guides");
	}

	lockEdition() {
		this.toggleLockEdition(true, "CustomTabs", "Guides");
	}

	unlockEdition() {
		this.localIsReadOnly = false;
		this.toggleLockEdition(false, "CustomTabs", "Guides");
	}

	async mounted() {
		if (this.currentId && this.currentId !== "novo") {
			await this.loadClientById();
		} else {
			this.toggleLockEdition(false, "CustomTabs");
		}
		this.getRelationFields();
		this.getAppointments();
	}

	async loadClientById() {
		if (this.currentId && this.currentId) {
			this.loading = true;
			const client: Client = await clientModel.read(this.$route.params.id);
			this.modelForm = new Client(client);
			this.corporateClientId = this.modelForm["corporateClient.id"];
			this.loading = false;
		}
	}

	async save() {
		if (this.currentTabIndex == 0) {
			this.currentTabIndex++;
			return;
		} else {
			if (this.currentSecondaryTabIndex !== 3) {
				this.currentSecondaryTabIndex++;
				return;
			}
		}

		Object.assign(this.modelForm, {
			dependents: this.modelForm.planInfoDependency,
			planInfoTreatment: this.modelForm.treatment,
			planInfoDependency: this.modelForm.dependency,
			homeCare: false,
		});

		this.loadingButton = true;

		try {
			await clientModel.create(this.modelForm);

			const message = this.modelForm?.id ? "O cliente foi atualizado" : "O cliente foi criado";
			showSuccessAlert(message);

			this.$router.back();
		} catch {
			showErrorAlert("Ocorreu um erro ao criar cliente!");
		} finally {
			this.loadingButton = false;
		}
	}

	async deleteClient() {
		await requireConfirmation("Confirma a exclusão?", "Não será possível reverter esta operação.", "Excluir");

		this.loadingErrorButton = true;
		try {
			await clientModel.delete(this.modelForm.id, "");
			showSuccessAlert("O cliente foi deletado");

			this.$router.back();
		} catch (error: any) {
			showErrorAlert(error?.message ?? "Ocorreu um erro ao deletar cliente!");
		} finally {
		}
	}

	cleanKeysForUpdate = [
		"id",
		"isComplete",
		"corporateClient.name",
		"corporateClient.cnpj",
		"corporateClient.clientKind",
		"corporateClient.otherClientKind",
		"corporateClient.personInChargeName",
		"corporateClient.personInChargePhoneNumber",
		"corporateClient.personInChargeEmail",
		"insurerPlan.name",
		"insurerPlan.description",
		"insurerPlan.emergencyService",
		"insurerPlan.emergencyServiceNumber",
		"insurerPlan.servicePlans",
		"firstSolutionAppointmentDate",
		"clientTreatments.clientTreatments",
		"clientTags.clientTags",
		"antecedentDiseases.antecedentDiseases",
		"address.latitude",
		"address.longitude",
		"secondaryAddress.latitude",
		"secondaryAddress.longitude",
		"enterpriseId",
	];

	keysEmptyArray = ["antecedentDiseases", "clientTreatments", "clientTags"];

	cleanFormUpdate() {
		Object.keys(this.modelForm).forEach((key: string) => {
			if (this.cleanKeysForUpdate.includes(key)) {
				delete this.modelForm[key];
			}
		});
	}

	transformFormValues() {
		this.keysEmptyArray.forEach((key: string) => {
			this.modelForm[key] = this.modelForm[key] || [];
		});
	}

	async updateClient() {
		this.isEntityLoading = true;
		this.cleanFormUpdate();
		this.transformFormValues();
		try {
			await clientModel.update(this.currentId, this.modelForm);
			showSuccessAlert("O cliente foi atualizado com sucesso");
			location.reload();
		} catch (error: any) {
			console.error(error);
			showErrorAlert("Ocorreu um erro ao alterar cliente!");
		} finally {
			this.isEntityLoading = false;
		}
	}

	changeTab(tabIndex: number) {
		this.currentTabIndex = tabIndex;
	}

	changeSelect(value: any, fieldKey: string) {
		if (fieldKey === "corporateClient.id") {
			this.corporateClientId = value;
			this.getRelationFields();
		}
	}

	back() {
		this.$router.back();
	}
}
